<ng-container [ngSwitch]="true">
  <ng-container *ngSwitchCase="!thirdPartyType">
    <video controls
    #video
      [style]="'width: 100%; height: 100%'"
      crossorigin="anonymous"
      controlslist="nodownload"
      #media
      [id]="id"
      preload="auto"
      [autoplay]="autoplay"
      [muted]="mute"
      [attr.controls]="controls ? 'true' : null"
      webkit-playsinline
      playsinline
      (play)="trackVgProgress($event)"
      (progress)="trackVgProgress($event)"
      (ended)="trackVgProgress($event)"
    >
      <source [src]="url" type="video/mp4" />
      <ng-container *ngIf="captions">
        <track
          [label]="captions.label"
          [kind]="captions.kind"
          [srclang]="captions.srclang"
          [src]="captions.src"
          [default]="captions.default"
        />
      </ng-container>
    </video>
  </ng-container>

  <ng-container *ngSwitchCase="thirdPartyType === 'yt'">
    <div class="ytPlayerWrap" [class.embed]="embed">
      <youtube-player
        #youtubePlayer
        [videoId]="ytId"
        [playerVars]="{
          controls: controls ? 1 : 0,
          rel: 0,
          loop: loop ? 1 : 0,
          modestbranding: 1
        }"
        [height]="ytPlayerHeight"
        [width]="ytPlayerWidth"
        (stateChange)="onYtPlayerStateChange($event)"
        disablePlaceholder
      ></youtube-player>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="thirdPartyType === 'vimeo'">
    <ush-vimeo-player [title]="title" [autplay]="autoplay" [url]="url"> </ush-vimeo-player>
  </ng-container>
</ng-container>

<div class="audio-description-toggler" tabindex="1" *ngIf="audioDescriptionUrl" (click)="onAudioDescTogglerClicked()">
  <span>{{ 'video.audio.description-toggler' | translate }}</span>
  <i class="pi pi-times" (click)="closeAudioDescToggler()"></i>
</div>
