import { Injectable } from '@angular/core';
import { AnyVideo, Movie, UplayVideo, Video } from '@usheru-hff/shared/domain';
import { BehaviorSubject } from 'rxjs';

type ComponentStatus = 'active' | 'closed';
interface ContentSettings {
  content: Video | Movie | UplayVideo | AnyVideo | undefined;
  animate?: boolean;
  accessibleUrl?: string;
  category?: string;
  videoSettings?: { muted?: boolean; autoplay?: boolean };
}
interface VideoEvent {
  event: 'play' | 'pause';
}
@Injectable({
  providedIn: 'root'
})
export class VideoFullscreenService {
  content$: BehaviorSubject<ContentSettings> = new BehaviorSubject(null);
  videoEvent$: BehaviorSubject<VideoEvent> = new BehaviorSubject(null);

  status = new BehaviorSubject<ComponentStatus>('closed');

  constructor() {}

  get content() {
    return this.content$.value;
  }

  play(contentSetting: ContentSettings): BehaviorSubject<ComponentStatus> {
    this.content$.next(contentSetting);
    this.status.next('active');
    return this.status;
  }

  setEvent(event: VideoEvent) {
    this.videoEvent$.next(event);
  }

  /**
   * Used when the fullscreen is closed
   */
  reset() {
    this.content$.next(null);
    this.videoEvent$.next(null);
    this.status.next('closed');
  }
}
