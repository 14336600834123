import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  HostListener,
  ChangeDetectorRef,
  AfterViewInit,
  afterNextRender,
  OnDestroy,
  ChangeDetectionStrategy,
  signal
} from '@angular/core';
import { YouTubePlayer } from '@angular/youtube-player';
import { first, takeUntil } from 'rxjs';

import { AnyVideo, Caption, getMovieGenres, getMovieTags, Movie, UplayVideo, Video } from '@usheru-hff/shared/domain';
import { TrackingService } from '@usheru-hff/shared/utils-tracking';

import { VideoFullscreenService } from '../infrastructure/video-fullscreen.service';
import { VimeoPlayerComponent } from './vimeo-player/vimeo-player.component';

type actions = 'Play' | 'Play25' | 'Play50' | 'Play75' | 'Play100';
const AUDIO_DESC_TTL = 120000;

/**
 * Component to display videos from files, YouTube or Vimeo.
 *
 * //TODO barbeitoalex propostal - organice life cycle methods. Split in specific method to improve docs.
 * //TODO barbeitoalex propostal - update translate text with an observable to the current language
 */
@Component({
  selector: 'ush-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Content to render. Prioriced over {@link url}.
   */
  @Input()
  content: Movie | Video | AnyVideo | UplayVideo | undefined;

  /**
   * Video to render from a link. Transformed into {@link content} {@link AnyVideo} type.
   *
   * //TODO barbeitoalex propostal - use the setter to transform into content
   */
  @Input()
  url: string;

  /**
   * Used for example in fullscreen YT videos.
   */
  @Input()
  controls = false;

  @Input() mute = false;

  /**
   * Display the video in the {@link VideoFullscreenComponent}.
   *
   * //TODO barbeitoalex propostal - change this to "modeDisplay" with type "embed" | "fullscreen". Maybe dialog video could be an option.
   */
  @Input()
  embed = false;

  /**
   * @deprecard Don't found any use
   */
  @Input()
  fullscreen = true;

  /**
   * @deprecard Don't found any use
   */
  @Input()
  play = true;

  /**
   * Status to know if restart the video when finish
   */
  @Input()
  loop = false;

  /**
   * @docsNotRequired
   */
  @Input()
  trackingCategory: string;

  /**
   * Event when a video ends
   *
   * //TODO barbeitoalex propostal - refractor to something like "finishedVideoEvent" to be more descriptive
   */
  // eslint-disable-next-line @angular-eslint/no-output-native, @angular-eslint/no-output-rename
  @Output('end')
  // eslint-disable-next-line @angular-eslint/no-output-native
  endEvent: EventEmitter<void> = new EventEmitter();

  @Output() stateChange = new EventEmitter();

  /**
   * Wrapper element of the {@link YtPlayerComponent}. Used to set "--width-yt" style
   * variable to adjust the resolution.
   */
  @ViewChild('ytPlayerWrap')
  ytPlayerWrap: HTMLElement;

  /**
   * @docsNotRequired
   */
  id: string;

  /**
   * Reference of the YouTube player provided by {@link YtPlayerComponent}.
   */
  @ViewChild(YouTubePlayer)
  ytPlayer: YouTubePlayer;

  /**
   * setInterval reference used to check the YouTube video status.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ytPlayerInterval: any;

  /**
   * Youtube video id reference extracted from the url.
   */
  ytId: string;

  /**
   * Status to know if the track when video start was set.
   */
  trackedPlay = false;

  /**
   * Status to know if the track when video reach the 25% of progress was set.
   */
  tracked25 = false;

  /**
   * Status to know if the track when video reach the 50% of progress was set.
   */
  tracked50 = false;

  /**
   * Status to know if the track when video reach the 75% of progress was set.
   */
  tracked75 = false;

  /**
   * Status to know if the track when video reach the 100% of progress was set.
   */
  tracked100 = false;

  /**
   * Title used in the tracking.
   *
   * //TODO barbeitoalex poropostal - refractor to "trackingTitle"
   */
  title: string;

  ytPlayerHeight = 0;
  ytPlayerWidth = 0;

  /**
   * Type of video if it is not a raw file.
   *
   * //TODO barbeitoalex propostal - Change to the actual used types.
   */
  thirdPartyType: string;

  /**
   * //TODO favio docs
   */
  @Input()
  audioDescriptionUrl: string;

  /**
   * //TODO favio docs
   */
  @Input()
  captions: Caption;

  /**
   * Video element referce. Used to play again when {@link loop}.
   */
  @ViewChild('video') videoRef: ElementRef;

  /**
   * Vimeo component referce.
   */
  @ViewChild(VimeoPlayerComponent) vimeo: VimeoPlayerComponent;

  @Output()
  rendered = new EventEmitter<VideoComponent>();

  @Output()
  destroyed = new EventEmitter();

  @Output()
  videoReady = new EventEmitter(false);

  set isVideoReady(value: boolean) {
    this.isVideoReadySig.set(value);
    this.videoReady.emit(value);
  }

  isVideoReadySig = signal<boolean>(false);

  /**
   * Used for vimeo videos
   */
  @Input()
  autoplay = false;

  @Output() pausedAtTheEnd = new EventEmitter<void>();
  /**
   * Used to stop the video at the end and reaunde without problems
   */
  pausedAtTheEndStatus = false;

  constructor(
    private fullscreenService: VideoFullscreenService,
    private trackingService: TrackingService,
    private cd: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {
    this.id = `video-${new Date().getTime() - Math.floor(Math.random() * 100000)}`;
    afterNextRender(() => {
      this.updateYTapi();
    });
  }

  ngOnInit(): void {
    if (this.url && !this.content) {
      this.content = { video: this.url };
    }
    this.startVideo(this.content);
  }

  ngAfterViewInit() {
    this.rendered.emit(this);
    if (this.thirdPartyType === 'yt') {
      this.setUpYtVideoSize();
    } else {
      this.isVideoReady = true;
    }
  }

  private setUpYtVideoSize() {
    const hostElement = this.elementRef.nativeElement;
    const hostWidth = hostElement.offsetWidth;
    const hostHeight = hostElement.offsetHeight;
    this.ytPlayerHeight = hostHeight;
    this.ytPlayerWidth = hostWidth;
  }

  /**
   * * Set some initial params acording the content.
   *
   * * Set if it is a {@link thirdPartyType}.
   *
   * //TODO barbeitoalex propostal - this could be split in 2 funciton and used in the setter {@link content}
   * @param content
   * @returns
   */
  startVideo(content: Video | Movie | UplayVideo | AnyVideo) {
    if (this.trackedPlay) {
      this.reanude();
      return;
    }
    // Is a Video or Movie?
    if ('video' in content && 'movie' in content) {
      this.url = (content as Video).video;
      this.title = `${(content as Video).title}`;
    } else if ('video' in content) {
      this.url = (content as Video).video;
      this.title = `Channel Video - ${(content as Video).title}`;
    } else if ('uPlayVideo' in content) {
      this.url = (content as UplayVideo).uPlayVideo;
      this.title = (content as UplayVideo).title;
    } else if ('friendlyUrl' in content) {
      this.url = (content as Movie).trTrailerLink;
      this.title = (content as Movie).title;
    } else {
      this.url = (content as AnyVideo).url;
      this.title = (content as AnyVideo).title;
    }

    if (!this.url) {
      console.warn('no video found to play');
      return;
    }

    // check if is a youtube video to save the video id
    if (
      this.url.startsWith('https://www.youtube.com/watch') ||
      this.url.startsWith('https://youtu.be/') ||
      this.url.startsWith('https://www.youtube.com/embed/')
    ) {
      this.thirdPartyType = 'yt';

      if (this.url.indexOf('https://www.youtube.com/watch') === 0) {
        this.ytId = this.url
          .split('?')[1]
          .split('&')
          .find(p => p.indexOf('v=') === 0)
          .replace('v=', '');
      } else if (this.url.indexOf('https://youtu.be/') === 0) {
        this.ytId = this.url.replace('https://youtu.be/', '');
      } else if (this.url.indexOf('https://www.youtube.com/embed/') === 0) {
        this.ytId = this.url.replace('https://www.youtube.com/embed/', '');
      }

      // or maybe it's vimeo
    } else if (this.url.startsWith('https://player.vimeo.com/video') || this.url.startsWith('https://vimeo.com/')) {
      // Use only the player.vimeo format
      this.url = this.url
        .replace('https://vimeo.com/', 'https://player.vimeo.com/video/')
        .replace('/video/video/', '/video/');
      this.thirdPartyType = 'vimeo';
    }
  }

  /**
   *
   */
  reanude() {
    this.waitToVideoReady(() => {
      this.pausedAtTheEndStatus = false;

      switch (this.thirdPartyType) {
        case 'yt':
          // check if the video is it at the end to restart it
          if (this.ytPlayer?.getCurrentTime() >= this.ytPlayer?.getDuration() - 1) {
            this.ytPlayer.seekTo(0, true);
          }

          this.reanudeYT();

          break;
        case 'vimeo':
          this.reanudeVimeo();
          break;
        default:
          // check if the video is it at the end to restart it
          if (
            (this.videoRef.nativeElement as HTMLVideoElement).currentTime >=
            (this.videoRef.nativeElement as HTMLVideoElement).duration - 1
          ) {
            (this.videoRef.nativeElement as HTMLVideoElement).currentTime = 0;
          }

          this.reanudeVideo();
      }
    });
  }

  private reanudeVideo() {
    const video = this.videoRef?.nativeElement as HTMLVideoElement;
    video.play();
  }

  private reanudeYT() {
    // if (this.autoplay) {
    if (this.ytPlayer) this.ytPlayer.playVideo();
    // }
  }

  private reanudeVimeo() {
    this.vimeo.play();
  }

  /**
   * Resets tracking and other variables.
   */
  resetValues() {
    this.url = null;
    this.thirdPartyType = null;
    this.ytPlayer = null;
    this.ytPlayerInterval = null;
    this.ytId = null;
    this.trackedPlay = false;
    this.tracked25 = false;
    this.tracked50 = false;
    this.tracked75 = false;
    this.tracked100 = false;
  }

  pause() {
    this.waitToVideoReady(() => {
      switch (this.thirdPartyType) {
        case 'yt':
          this.pauseYT();
          break;
        case 'vimeo':
          this.pauseVimeo();
          break;
        default:
          this.pauseVideo();
      }
    });
  }

  private pauseVideo() {
    const video = this.videoRef?.nativeElement as HTMLVideoElement;
    video.pause();
  }

  private pauseYT() {
    this.ytPlayer.pauseVideo();
  }

  private pauseVimeo() {
    this.vimeo.pause();
  }

  setMute() {
    this.waitToVideoReady(() => {
      switch (this.thirdPartyType) {
        case 'yt':
          this.muteYT();
          break;
        case 'vimeo':
          this.muteVimeo();
          break;
        default:
          this.muteVideo();
      }
      this.mute = true;
    });
  }

  setUnmute() {
    this.waitToVideoReady(() => {
      switch (this.thirdPartyType) {
        case 'yt':
          this.unmuteYT();
          break;
        case 'vimeo':
          this.unmuteVimeo();
          break;
        default:
          this.unmuteVideo();
      }
      this.mute = false;
    });
  }

  private muteVideo() {
    this.mute = true;
  }

  private unmuteVideo() {
    this.mute = false;
  }

  private muteYT() {
    if (this.ytPlayer) this.ytPlayer.mute();
  }

  private unmuteYT() {
    if (this.ytPlayer) this.ytPlayer.unMute();
  }

  private muteVimeo() {
    this.vimeo.mute();
  }

  private unmuteVimeo() {
    this.vimeo.unmute();
  }

  /**
   * // Executed on the {@link ytPlayerInterval} and {@link onYtPlayerStateChange}.
   */
  updateYtProgress(): void {
    if (this.ytPlayer) {
      const duration = this.ytPlayer.getDuration();
      const progress25 = duration * 0.25;
      const progress50 = duration * 0.5;
      const progress75 = duration * 0.75;
      const time = this.ytPlayer.getCurrentTime();

      let action: actions;
      if (!this.tracked25 && time >= progress25 && time < progress50) {
        action = 'Play25';
        this.tracked25 = true;
      }
      if (!this.tracked50 && time >= progress50 && time < progress75) {
        action = 'Play50';
        this.tracked50 = true;
      }
      if (!this.tracked75 && time >= progress75 && time < duration) {
        action = 'Play75';
        this.tracked75 = true;
      }

      if (action) {
        this.addTracking(action);
      }
    }
  }

  /**
   * Attached to {@link YtPlayerComponent} ready event. Used to send instructions
   * to the player like mute or play.
   * @param playerEvent
   */
  onYoutubeReady(playerEvent: any): void {
    if (this.mute) {
      this.muteYT();
    } else {
      this.unmuteYT();
    }

    this.reanudeYT();
  }

  /**
   * Attached to {@link YtPlayerComponent} change event. Used to tracking
   * the video status.
   *    UNSTARTED = -1,
        ENDED = 0,
        PLAYING = 1,
        PAUSED = 2,
        BUFFERING = 3,
        CUED = 5
   * @param event
   */
  onYtPlayerStateChange(event): void {
    // when event.data == 2 then update video is paused
    if (event.data === 5) {
      this.isVideoReady = true;
    }
    this.stateChange.emit(event);
    const state = this.ytPlayer.getPlayerState();
    if (!this.trackedPlay && state !== -1) {
      this.addTracking('Play');
      this.trackedPlay = true;
    }

    if (state === -1 && this.autoplay) {
      this.onYoutubeReady(null);
    }

    this.updateYtProgress();
    if (!this.ytPlayerInterval && state === 1) {
      this.ytPlayerInterval = setInterval(() => this.updateYtProgress(), 4000);
    } else if (state === 2 || state === 0) {
      if (!this.tracked100 && state === 0) {
        this.addTracking('Play100');
        this.tracked100 = true;
        this.endEvent.emit();

        if (this.loop) {
          setTimeout(() => this.ytPlayer.playVideo());
          // check if is playing
        } else if (!this.pausedAtTheEndStatus && this.ytPlayer.getCurrentTime() >= this.ytPlayer.getDuration() - 1) {
          // pause video and set last frame
          this.pause();
          this.pausedAtTheEndStatus = true;
          this.pausedAtTheEnd.emit();
          this.ytPlayer.seekTo(this.ytPlayer.getDuration() - 1, true);
        }
      }

      clearInterval(this.ytPlayerInterval);
      this.ytPlayerInterval = null;
    }
  }

  /**
   * Used to tracking normal videos.
   * @param event
   */
  trackVgProgress(event): void {
    let action: actions;

    switch (event.type) {
      case 'play': {
        if (!this.trackedPlay) {
          action = 'Play';
          this.trackedPlay = true;
        }
        break;
      }
      case 'ended': {
        if (!this.tracked100) {
          action = 'Play100';
          this.tracked100 = true;
          this.endEvent.emit();
        }

        if (this.loop) {
          (this.videoRef.nativeElement as HTMLVideoElement).currentTime = 0;
          (this.videoRef.nativeElement as HTMLVideoElement).play();
          // check if is in the end
        } else if (
          (!this.pausedAtTheEndStatus && (this.videoRef.nativeElement as HTMLVideoElement)).currentTime >=
          (this.videoRef.nativeElement as HTMLVideoElement).duration - 1
        ) {
          this.pausedAtTheEndStatus = true;
          this.pause();
          this.pausedAtTheEnd.emit();
          (this.videoRef.nativeElement as HTMLVideoElement).currentTime = (
            this.videoRef.nativeElement as HTMLVideoElement
          ).duration;
        }
        break;
      }
      case 'progress': {
        const duration = event.srcElement.duration;
        const progress25 = duration * 0.25;
        const progress50 = duration * 0.5;
        const progress75 = duration * 0.75;
        const time = event.srcElement.currentTime;

        if (!this.tracked25 && time >= progress25 && time < progress50) {
          action = 'Play25';
          this.tracked25 = true;
        }
        if (!this.tracked50 && time >= progress50 && time < progress75) {
          action = 'Play50';
          this.tracked50 = true;
        }
        if (!this.tracked75 && time >= progress75 && time < duration) {
          action = 'Play75';
          this.tracked75 = true;
        }
        break;
      }
    }

    if (action) {
      this.addTracking(action);
    }
  }

  /**
   * Used to create the tracking.
   * @param action
   */
  private addTracking(action: actions) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const extraProps: any = {};

    switch (true) {
      case this.instanceOfMovie(this.content): {
        const movie = this.content as Movie;
        extraProps.movie = movie.title;
        extraProps.idMovie = movie.id;
        extraProps.imdb = movie.imdb;
        extraProps.tagsMovie = getMovieTags(movie);
        extraProps.genresMovie = getMovieGenres(movie);
        break;
      }
      case this.instanceOfVideo(this.content): {
        const video = this.content as Video;
        if (video.movieId || video.movie) {
          extraProps.movie = video.movieTitle || video.movie?.title;
          extraProps.idMovie = video.movieId || video.movie?.id;
          extraProps.imdb = video.movieImdb || video.movie?.imdb;
          extraProps.tagsMovie = getMovieTags(video.movie);
          extraProps.genresMovie = getMovieGenres(video.movie);
        }
        break;
      }
    }

    this.trackingService.trackEvent({
      action,
      properties: {
        label: this.title,
        category: this.trackingCategory || 'Video',
        ...extraProps
      }
    });
  }

  /**
   * @docsNotRequired
   * @param object
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private instanceOfMovie(object: any): object is Movie {
    return 'trTrailerLink' in object;
  }

  /**
   * @docsNotRequired
   * @param object
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private instanceOfVideo(object: any): object is Video {
    return 'video' in object;
  }

  /**
   * //TODO favio docs
   */
  onAudioDescTogglerClicked() {
    this.url = this.audioDescriptionUrl;
    this.audioDescriptionUrl = '';
  }

  /**
   * //TODO favio docs
   */
  closeAudioDescToggler() {
    this.audioDescriptionUrl = '';
  }

  waitToVideoReady(fun: () => void) {
    if (this.isVideoReadySig()) {
      fun();
    } else {
      this.videoReady.pipe(first(val => val)).subscribe(() => {
        fun();
      });
    }
  }

  private updateYTapi() {
    if (!document.querySelector('script[data-ytapi]')) {
      const script = document.createElement('script');
      script.dataset.ytapi = 'loaded';
      script.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(script);
    }
  }

  ngOnDestroy(): void {
    this.destroyed.emit();
  }
}
